import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mb-3" }
const _hoisted_3 = { class: "card pt-4 mb-xl-9" }
const _hoisted_4 = { class: "card-header border-0" }
const _hoisted_5 = { class: "card-title" }
const _hoisted_6 = { class: "fw-bolder" }
const _hoisted_7 = { class: "align-items-end flex-column" }
const _hoisted_8 = { class: "btn btn-primary btn-sm btn-light btn-active-light-primary me-3" }
const _hoisted_9 = ["href", "onClick"]
const _hoisted_10 = { class: "menu-title" }
const _hoisted_11 = { class: "card-body pt-0" }
const _hoisted_12 = { class: "fw-bolder fs-2" }
const _hoisted_13 = { class: "fs-7 fw-normal text-muted" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-12" }
const _hoisted_16 = { class: "card pt-4 mb-xl-9" }
const _hoisted_17 = { class: "card-body" }
const _hoisted_18 = {
  class: "table-responsive",
  id: "printableTable"
}
const _hoisted_19 = { class: "table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" }
const _hoisted_20 = { class: "fw-bolder text-muted" }
const _hoisted_21 = { class: "min-w-120px" }
const _hoisted_22 = { class: "min-w-120px" }
const _hoisted_23 = { class: "min-w-120px" }
const _hoisted_24 = { class: "min-w-120px" }
const _hoisted_25 = { class: "min-w-120px" }
const _hoisted_26 = { class: "text-muted fw-bold text-muted d-block fs-7" }
const _hoisted_27 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
}
const _hoisted_28 = { class: "text-dark fw-bolder text-hover-primary mb-1 fs-6" }
const _hoisted_29 = { class: "text-dark fw-bolder text-hover-primary mb-1 fs-6" }
const _hoisted_30 = { class: "text-dark fw-bolder text-hover-primary mb-1 fs-6" }
const _hoisted_31 = {
  key: 0,
  class: "badge-light-primary badge text-default"
}
const _hoisted_32 = {
  key: 1,
  class: "badge-light-success badge text-success"
}
const _hoisted_33 = {
  key: 2,
  class: "badge-light-warning badge text-warning"
}
const _hoisted_34 = { class: "text-dark fw-bolder mb-1 fs-6 text-center" }
const _hoisted_35 = { key: 0 }
const _hoisted_36 = ["data-bs-target"]
const _hoisted_37 = ["id"]
const _hoisted_38 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_39 = { class: "modal-content" }
const _hoisted_40 = { class: "modal-header" }
const _hoisted_41 = {
  class: "modal-title",
  id: "commentModalLabel"
}
const _hoisted_42 = { class: "modal-body fw-normal" }
const _hoisted_43 = { class: "text-end" }
const _hoisted_44 = ["onClick"]
const _hoisted_45 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t("pages.roadmap.title")), 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_router_link, { to: "/roadmap/related-roadmap/" }, {
                default: _withCtx(() => [
                  _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.$t("pages.roadmap.perimeterRoadmap")), 1)
                ]),
                _: 1
              }),
              _createElementVNode("a", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (this.downloadRoadmap && this.downloadRoadmap(...args))),
                class: "btn btn-primary btn-sm btn-light btn-active-light-primary me-3"
              }, _toDisplayString(_ctx.$t("general.download")), 1),
              _createVNode(_component_router_link, { to: "/add-roadmap" }, {
                default: _withCtx(({ href, navigate }) => [
                  _createElementVNode("a", {
                    class: "btn btn-primary btn-sm btn-light btn-active-light-primary",
                    href: href,
                    onClick: navigate
                  }, [
                    _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("pages.roadmap.addAction")), 1)
                  ], 8, _hoisted_9)
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t("pages.roadmap.desc")), 1)
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("table", _hoisted_19, [
                _createElementVNode("thead", null, [
                  _createElementVNode("tr", _hoisted_20, [
                    _createElementVNode("th", _hoisted_21, _toDisplayString(_ctx.$t("pages.roadmap.action")), 1),
                    _createElementVNode("th", _hoisted_22, _toDisplayString(_ctx.$t("pages.roadmap.deadline")), 1),
                    _createElementVNode("th", _hoisted_23, _toDisplayString(_ctx.$t("pages.roadmap.contributor")), 1),
                    _createElementVNode("th", _hoisted_24, _toDisplayString(_ctx.$t("pages.roadmap.impact")), 1),
                    _createElementVNode("th", _hoisted_25, _toDisplayString(_ctx.$t("pages.roadmap.status")), 1),
                    _cache[1] || (_cache[1] = _createElementVNode("th", { class: "min-w-120px text-center" }, "Notes", -1)),
                    _cache[2] || (_cache[2] = _createElementVNode("th", { class: "min-w-120px text-end" }, null, -1))
                  ])
                ]),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roadmaps, (action) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: action.id
                    }, [
                      _createElementVNode("td", null, [
                        _createElementVNode("span", _hoisted_26, _toDisplayString(action.category.label), 1),
                        _createElementVNode("a", _hoisted_27, _toDisplayString(action.label), 1)
                      ]),
                      _createElementVNode("td", _hoisted_28, _toDisplayString(_ctx.$moment(action.deadline).format("DD/MM/YYYY")), 1),
                      _createElementVNode("td", _hoisted_29, _toDisplayString(action.collaborators), 1),
                      _createElementVNode("td", _hoisted_30, _toDisplayString(action.impact), 1),
                      _createElementVNode("td", null, [
                        (action.status === 'TODO')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_31, _toDisplayString(_ctx.$t("pages.roadmap.todo")), 1))
                          : (action.status === 'DONE')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_32, _toDisplayString(_ctx.$t("pages.roadmap.done")), 1))
                            : (action.status === 'INPR')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_33, _toDisplayString(_ctx.$t("pages.roadmap.inProgress")), 1))
                              : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("td", _hoisted_34, [
                        (action.commentary === '')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_35))
                          : (_openBlock(), _createElementBlock("a", {
                              key: 1,
                              href: "#",
                              "data-bs-toggle": "modal",
                              "data-bs-target": '#commentModal' +action.pk 
                            }, _cache[3] || (_cache[3] = [
                              _createElementVNode("i", { class: "far fa-eye text-primary fs-4" }, null, -1)
                            ]), 8, _hoisted_36)),
                        _createElementVNode("div", {
                          class: "modal fade",
                          id: 'commentModal' + action.pk,
                          tabindex: "-1",
                          "aria-labelledby": "commentModalLabel",
                          "aria-hidden": "true"
                        }, [
                          _createElementVNode("div", _hoisted_38, [
                            _createElementVNode("div", _hoisted_39, [
                              _createElementVNode("div", _hoisted_40, [
                                _createElementVNode("h5", _hoisted_41, _toDisplayString(action.label) + " - " + _toDisplayString(_ctx.$t("pages.roadmap.commentaries")), 1),
                                _cache[4] || (_cache[4] = _createElementVNode("button", {
                                  type: "button",
                                  class: "btn-close",
                                  "data-bs-dismiss": "modal",
                                  "aria-label": "Close"
                                }, null, -1))
                              ]),
                              _createElementVNode("div", _hoisted_42, _toDisplayString(action.commentary), 1)
                            ])
                          ])
                        ], 8, _hoisted_37)
                      ]),
                      _createElementVNode("td", _hoisted_43, [
                        _createElementVNode("a", {
                          onClick: ($event: any) => (_ctx.editRoadmap(action)),
                          class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                        }, _cache[5] || (_cache[5] = [
                          _createElementVNode("span", { class: "svg-icon svg-icon-3" }, [
                            _createElementVNode("svg", {
                              fill: "none",
                              viewBox: "0 0 24 24",
                              height: "24",
                              width: "24",
                              xmlns: "http://www.w3.org/2000/svg"
                            }, [
                              _createElementVNode("path", {
                                xmlns: "http://www.w3.org/2000/svg",
                                opacity: "0.3",
                                d: "M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z",
                                fill: "black"
                              }),
                              _createElementVNode("path", {
                                xmlns: "http://www.w3.org/2000/svg",
                                d: "M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z",
                                fill: "black"
                              })
                            ])
                          ], -1)
                        ]), 8, _hoisted_44),
                        _createElementVNode("a", {
                          onClick: ($event: any) => (this.deleteAction(action)),
                          class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                        }, _cache[6] || (_cache[6] = [
                          _createStaticVNode("<span class=\"svg-icon svg-icon-3\"><svg fill=\"none\" viewBox=\"0 0 24 24\" height=\"24\" width=\"24\" xmlns=\"http://www.w3.org/2000/svg\"><path xmlns=\"http://www.w3.org/2000/svg\" d=\"M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z\" fill=\"black\"></path><path xmlns=\"http://www.w3.org/2000/svg\" opacity=\"0.5\" d=\"M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z\" fill=\"black\"></path><path xmlns=\"http://www.w3.org/2000/svg\" opacity=\"0.5\" d=\"M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z\" fill=\"black\"></path></svg></span>", 1)
                        ]), 8, _hoisted_45)
                      ])
                    ]))
                  }), 128))
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}